<template>
  <Page :title="$t('users.title')" :classes="['page--users']">

    <UsersTable
      :filter="filter"
      :limit="10"
    ></UsersTable>

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import UsersTable from '@/components/users/UsersTable.vue';

export default {
  name: 'UsersPage',
  components: {
    UsersTable,
  },
  data() {
    return {
      filter: {
      },
    };
  },
  computed: {
    ...mapGetters(['self']),
  },
};
</script>
