import debounce from 'debounce';
/**
 * Model Table Mixin
 * Common functions and properties for model-based tables
 */
export default {
  emits: [
    'update:selected',
  ],
  data() {
    return {
      offset: 0,
      storedLimit: undefined,
      isLoading: false,
      isReady: false,
      requestDataTrigger: 0,
    };
  },
  props: {
    filter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    limit: {
      type: Number,
      required: false,
      default: +process.env.VUE_APP_API_LIMIT_DEFAULT,
    },
    rows: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    selected: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    /**
     * Proxy for storing limit prop
     */
    actualLimit: {
      get() {
        return +this.storedLimit || +this.limit;
      },
      set(value) {
        this.storedLimit = +value;
      },
    },
  },
  created() {
    // If the user has changed previusly the pagination limit, recover it
    if (localStorage.getItem(`${this.$options.name}.storedLimit`)) {
      this.storedLimit = +localStorage.getItem(`${this.$options.name}.storedLimit`);
    }
  },
  mounted() {
    // TODO: Error. Current status: when multiple tables are initialized
    // on the same page, debounce mix all the calls
    this.debounce(this.requestData);
  },
  methods: {
    /**
     * Optional functions to implement:
     * - onRowClick handles regular clicks
     * - onRowMidClick handles mouse wheel clicks
     */
    onRowClick() {},
    onRowMidClick() {},

    /**
     * API Call debouncer
     */
    debounce: debounce((func) => { func(); }, 100),

    /**
     * Get request params as a object
     */
    composeRequestParams() {
      const params = {
        ...this.filter,
        offset: this.offset,
        limit: this.actualLimit,
      };
      const keys = Object.getOwnPropertyNames(params);
      for (let i = 0; i < keys.length; i += 1) {
        if (params[keys[i]] === null
          || params[keys[i]] === undefined
          || params[keys[i]] === '') {
          delete params[keys[i]];
        }
      }
      return params;
    },

    /**
     * API Call
     */
    requestData() {
      if (!this.model) {
        console.error('component data.model not defined');
        return;
      }
      if (this.rows.length) {
        // Preloaded rows -> do not request data
        this.$store.dispatch('updateModels', {
          model: this.model,
          data: { results: this.rows },
        });
        this.isReady = true;
        return;
      }
      this.isLoading = true;
      this.$store.dispatch('list', {
        model: this.model,
        updateModel: this.updateModel,
        params: this.composeRequestParams(),
      }).finally(() => {
        this.isLoading = false;
        this.isReady = true;
      });
    },
  },
  watch: {
    offset: 'requestData',
    requestDataTrigger: 'requestData',
    actualLimit() {
      this.debounce(this.requestData);
    },
    storedLimit(value) {
      localStorage.setItem(`${this.$options.name}.storedLimit`, value);
    },
    filter: {
      handler() {
        this.debounce(this.requestData);
      },
      deep: true,
    },
  },
};
