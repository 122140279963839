<template>
  <Table
    :rows="users"
    :columns="columns"
    :rowactions="rowactions"
    :isReady="isReady"
    :isLoading="isLoading"
    :pagination="usersPagination"
    :filters="filters"
    :filter="filter"
    v-model:offset="offset"
    v-model:limit="actualLimit"
    :classes="['table--user']"
  ></Table>
</template>

<script>
import { mapGetters } from 'vuex';
import ModelTableMixin from '@/components/layout/table/ModelTableMixin';

export default {
  name: 'UsersTable',
  mixins: [
    ModelTableMixin,
  ],
  data() {
    return {
      model: 'user',
      updateModel: true,
      columns: [{
        head: this.$t('common.user'),
        class: 'table__chip',
        component: 'UserChip',
        componentAttrs: (user) => ({ user }),
      }, {
        head: this.$t('common.active'),
        class: 'status',
        component: 'Status',
        componentAttrs: (item) => ({ value: item.is_active ? 'active' : 'pending' }),
      }],
      filters: [{
        component: 'InputText',
        property: 'email',
        componentProps: {
          label: this.$t('common.email'),
        },
      }],
      rowactions: [{
        label: this.$t('common.change_mark_revised'),
        class: (item) => (item.is_active ? 'approved' : 'pending'),
        function: this.changeApprovedStatus,
      }],
    };
  },
  computed: {
    ...mapGetters(['users', 'usersPagination']),
  },
  methods: {
    changeApprovedStatus(item) {
      this.$store.dispatch('partial_update', {
        model: 'user',
        id: item.id,
        data: {
          is_active: !item.is_active,
        },
      }).then((response) => {
        const idx = this.users.findIndex((i) => i.id === response.data.id);
        this.users[idx] = response.data;
      });
    },
  },
};
</script>
